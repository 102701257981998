<template>
  <div>
    <v-btn
      v-if="$_hasRole(['thesisAdmin'])"
      fab
      color="success"
      fixed
      bottom
      right
      :to="{ name: 'ThesisEdit', params: { id: 0, schoolYear: schoolYear } }"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
    <v-toolbar flat>
      <template v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
        <v-dialog scrollable max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dense outlined text v-bind="attrs" v-on="on">
              <v-icon left>mdi-filter</v-icon> Filter
            </v-btn>
          </template>
          <v-card>
            <v-list subheader>
              <v-subheader>Filter</v-subheader>

              <v-list-item-group v-model="filter">
                <v-list-item value="coach" active-class="primary--text">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>Betreuung fehlt</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-list-item value="assessor" active-class="primary--text">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title
                        >keine Zweitbeurteilung</v-list-item-title
                      >
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
              <v-subheader>Abteilung</v-subheader>
              <v-list-item-group v-model="division">
                <v-list-item
                  v-for="division in divisions"
                  :value="division.id"
                  :key="'division' + division.id"
                  active-class="primary--text"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ division.code }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        off-icon="mdi-radiobox-blank"
                        on-icon="mdi-radiobox-marked"
                        :input-value="active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
        <v-spacer />
        <SchoolYearPicker
          outlined
          text
          v-model="schoolYear"
          :disabled="loading"
        />
      </template>
      <template v-else>
        <v-btn-toggle
          active-class="primary--text"
          v-model="filter"
          dense
          class="mr-2"
        >
          <v-btn outlined text value="coach"> Betreuung fehlt </v-btn>
          <v-btn outlined text value="assessor"> keine Zweitlesung </v-btn>
        </v-btn-toggle>
        <v-btn-toggle
          v-model="division"
          dense
          class="mr-2"
          active-class="primary--text"
        >
          <v-btn
            v-for="division in divisions"
            :key="'divistionbtn' + division.id"
            outlined
            text
            :value="division.id"
          >
            {{ division.code }}
          </v-btn>
        </v-btn-toggle>
        <SchoolYearPicker
          outlined
          text
          v-model="schoolYear"
          :disabled="loading"
        />
        <v-menu offset-y v-if="$_hasRole(['thesisAdmin'])">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!division"
              v-bind="attrs"
              v-on="on"
              class="ml-2"
              outlined
              color="error"
            >
              <v-icon left>mdi-email</v-icon> E-Mail
            </v-btn>
          </template>
          <v-list subheader>
            <v-subheader>E-Mail-Programm</v-subheader>
            <v-list-item :href="'mailto:' + emailCoaches.join(',')">
              <v-list-item-title>Betreuung </v-list-item-title>
              <v-chip>{{ emailCoaches.length }}</v-chip>
            </v-list-item>
            <v-list-item :href="'mailto:' + emailAssessors.join(',')">
              <v-list-item-title>Zweitbeurteilung</v-list-item-title>
              <v-chip>{{ emailAssessors.length }}</v-chip>
            </v-list-item>
            <v-list-item :href="'mailto:' + emailAll.join(',')">
              <v-list-item-title>Alle LK</v-list-item-title>
              <v-chip>{{ emailAll.length }}</v-chip>
            </v-list-item>
            <v-divider />
            <v-subheader>Outlook Web</v-subheader>
            <v-list-item
              :href="
                'https://outlook.office365.com/owa/?rru=compose&to=' +
                emailCoaches.join(',')
              "
              target="_blank"
            >
              <v-list-item-title>Betreuung </v-list-item-title>
              <v-chip>{{ emailCoaches.length }}</v-chip>
            </v-list-item>
            <v-list-item
              :href="
                'https://outlook.office365.com/owa/?rru=compose&to=' +
                emailAssessors.join(',')
              "
              target="_blank"
            >
              <v-list-item-title>Zweitbeurteilung</v-list-item-title>
              <v-chip>{{ emailAssessors.length }}</v-chip>
            </v-list-item>
            <v-list-item
              :href="
                'https://outlook.office365.com/owa/?rru=compose&to=' +
                emailAll.join(',')
              "
              target="_blank"
            >
              <v-list-item-title>Alle LK</v-list-item-title>
              <v-chip>{{ emailAll.length }}</v-chip>
            </v-list-item>
          </v-list>
        </v-menu>

        <ReportButton
          v-if="$_hasRole(['thesisAdmin'])"
          class="ml-2"
          outlined
          color="success"
          :parameters="{
            schoolYear: schoolYear ? schoolYear.id : 0,
            division: division,
          }"
          resource="report/theses"
          >xls {{ divisionCode }}</ReportButton
        >
        <v-spacer />
        <v-text-field
          hide-details
          single-line
          label="Name oder Küerzel"
          v-model="search"
          append-icon="mdi-magnify"
          clearable
        ></v-text-field>
      </template>

      <template
        slot="extension"
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      >
        <v-text-field
          hide-details
          single-line
          label="Name oder Küerzel"
          v-model="search"
          append-icon="mdi-magnify"
          clearable
        ></v-text-field>
      </template>
    </v-toolbar>
    <v-card>
      <v-system-bar>Maturaarbeiten</v-system-bar>

      <v-data-table
        :headers="headers"
        :items="filteredItems"
        :loading="loading"
        sort-by="student"
      >
        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.student" />
        </template>
        <template v-slot:item.firstCoach="{ item }">
          <div v-if="canRetreatFirstCoach(item)">
            <v-chip
              color="primary"
              outlined
              @click="retreatFirstCoach(item.id)"
              close
              close-icon="mdi-delete"
            >
              <v-avatar left>
                <PortraitImage :value="item.firstCoach" />
              </v-avatar>

              <PersonName :value="item.firstCoach" />

              <slot />
            </v-chip>
          </div>
          <PersonItem
            v-else-if="item.firstCoach"
            small
            :value="item.firstCoach"
          />
          <v-chip
            v-else-if="canFirstCoach(item)"
            color="primary"
            outlined
            @click="beFirstCoach(item.id)"
            ><v-avatar left><v-icon>mdi-account-plus</v-icon></v-avatar
            >eintragen</v-chip
          >
          <div v-if="canRetreatSecondCoach(item)">
            <v-chip
              color="primary"
              outlined
              @click="retreatSecondCoach(item.id)"
              close
              close-icon="mdi-delete"
            >
              <v-avatar left>
                <PortraitImage :value="item.secondCoach" />
              </v-avatar>

              <PersonName :value="item.secondCoach" />

              <slot />
            </v-chip>
          </div>
          <PersonItem
            v-else-if="item.secondCoach"
            small
            :value="item.secondCoach"
          />
          <v-chip
            v-else-if="canSecondCoach(item)"
            color="primary"
            outlined
            @click="beSecondCoach(item.id)"
            ><v-avatar left><v-icon>mdi-account-plus</v-icon></v-avatar
            >eintragen</v-chip
          >
        </template>
        <template v-slot:item.assessor="{ item }">
          <div v-if="canRetreatAssessor(item)">
            <v-chip
              color="primary"
              outlined
              @click="retreatAssessor(item.id)"
              close
              close-icon="mdi-delete"
            >
              <v-avatar left>
                <PortraitImage :value="item.assessor" />
              </v-avatar>

              <PersonName :value="item.assessor" />

              <slot />
            </v-chip>
          </div>
          <PersonItem v-else-if="item.assessor" small :value="item.assessor" />
          <v-chip
            v-else-if="canAssess(item)"
            color="primary"
            outlined
            @click="assess(item.id)"
            ><v-avatar left><v-icon>mdi-account-plus</v-icon></v-avatar
            >übernehmen</v-chip
          >
        </template>
        <template v-slot:item.presentation="{ item }">
          <v-chip small dark v-if="item.presentationDate">
            <DateValue :value="item.presentationDate" />
          </v-chip>
          <v-chip
            small
            dark
            v-if="item.presentationTime && item.presentationTime != '00:00'"
            >{{ item.presentationTime }}
          </v-chip>
          <v-tooltip left v-if="item.presentationRoom">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                small
                dark
                v-if="item.presentationRoom"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.presentationRoom.code }}
              </v-chip>
            </template>
            <span>{{ item.presentationRoom.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="$_hasRole('thesisAdmin')"
            icon
            color="primary"
            :to="{ name: 'ThesisEdit', params: { id: item.id } }"
          >
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </template>
      </v-data-table></v-card
    >
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { comparePeople, searchPerson } from "common/utils/people";

import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import ReportButton from "@/components/ReportButton.vue";
import SchoolYearPicker from "@/components/SchoolYearPicker.vue";

export default defineComponent({
  components: {
    DateValue,
    PersonItem,
    PersonName,
    PortraitImage,
    ReportButton,
    SchoolYearPicker,
  },

  data() {
    return {
      division: null,
      filter: null,
      loading: false,
      items: [],
      search: null,
      schoolYear: null,
      settings: false,
      headers: [
        { text: "Schüler*in", value: "student", sort: comparePeople },
        {
          text: "Titel",
          value: "title",
        },
        { text: "Betreuung", value: "firstCoach", sort: comparePeople },
        { text: "Zweitbeurteilung", value: "assessor", sort: comparePeople },
        { text: "Präsentation", value: "presentation" },
        { text: "", value: "actions" },
      ],
      divisions: [
        { id: 1, code: "GH" },
        { id: 2, code: "MN" },
        { id: 3, code: "WR" },
      ],
    };
  },
  computed: {
    divisionCode() {
      return this.division ? this.divisions[this.division - 1].code : "";
    },
    emailAll() {
      return [
        ...new Set([...this.emailAssessors, ...this.emailCoaches]),
      ].sort();
    },
    emailAssessors() {
      return [
        ...new Set(
          this.items
            .filter(
              (el) =>
                el.assessor &&
                el.student.division &&
                el.student.division.id == this.division
            )
            .map((el) => el.assessor.emailSchool)
        ),
      ].sort();
    },
    emailCoaches() {
      return [
        ...new Set([
          ...this.items
            .filter(
              (el) =>
                el.firstCoach &&
                el.student.division &&
                el.student.division.id == this.division
            )
            .map((el) => el.firstCoach.emailSchool),
          ...this.items
            .filter(
              (el) =>
                el.secondCoach &&
                el.student.division &&
                el.student.division.id == this.division
            )
            .map((el) => el.secondCoach.emailSchool),
        ]),
      ].sort();
    },

    filteredItems() {
      return this.items.filter(
        (item) =>
          (!this.filter ||
            (this.filter == "coach" &&
              (!item.firstCoach || !item.secondCoach)) ||
            (this.filter == "assessor" &&
              !item.assessor &&
              !(item.firstCoach && item.secondCoach))) &&
          (!this.division ||
            (item.student.division &&
              this.division == item.student.division.id)) &&
          (!this.search ||
            searchPerson(item.student, this.search) ||
            searchPerson(item.firstCoach, this.search) ||
            searchPerson(item.secondCoach, this.search) ||
            searchPerson(item.assessor, this.search) ||
            (item.title &&
              item.title.toLowerCase().includes(this.search.toLowerCase())))
      );
    },
  },
  watch: {
    schoolYear() {
      this.fetchData();
    },
  },

  methods: {
    canAssess(item) {
      return (
        item.firstCoach != null &&
        !this.$_isPerson(item.firstCoach) &&
        !this.$_isPerson(item.secondCoach) &&
        item.assessor == null &&
        this.settings.assessorNow &&
        !(item.firstCoach != null && item.secondCoach != null)
      );
    },
    canFirstCoach(item) {
      return item.firstCoach == null && this.settings.coachNow;
    },
    canSecondCoach(item) {
      return (
        item.firstCoach != null &&
        item.assessor == null &&
        !this.$_isPerson(item.firstCoach) &&
        item.secondCoach == null &&
        this.settings.coachNow
      );
    },
    async fetchData() {
      if (!this.schoolYear) {
        return;
      }
      this.loading = true;
      this.items = await this.apiList({
        resource: "thesis/thesis",
        query: "schoolYear=" + this.schoolYear.id,
      });

      this.items.sort((a, b) => comparePeople(a.student, b.student));

      this.settings = await this.apiList({
        resource: "thesis/settings",
      });
      this.loading = false;
    },
    async assess(id) {
      if (
        await this.$root.confirm({
          message: "Möchtest du diese Zweitbeurteilung übernehmen?",
          color: "success",
          icon: "mdi-plus",
        })
      ) {
        await this.apiPatch({
          resource: "thesis/thesis",
          id: id,
          key: "assessor",
          value: this.$_profilePerson.id,
        });
        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: "schoolYear=" + this.schoolYear.id,
        });
      }
    },
    async beFirstCoach(id) {
      if (
        await this.$root.confirm({
          message: "Wird diese Arbeit von dir betreut?",
          color: "success",
          icon: "mdi-plus",
        })
      ) {
        await this.apiPatch({
          resource: "thesis/thesis",
          id: id,
          key: "firstCoach",
          value: this.$_profilePerson.id,
        });
        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: "schoolYear=" + this.schoolYear.id,
        });
      }
    },
    async beSecondCoach(id) {
      if (
        await this.$root.confirm({
          message: "Wird diese Arbeit von dir co-betreut?",
          color: "success",
          icon: "mdi-plus",
        })
      ) {
        await this.apiPatch({
          resource: "thesis/thesis",
          id: id,
          key: "secondCoach",
          value: this.$_profilePerson.id,
        });
        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: "schoolYear=" + this.schoolYear.id,
        });
      }
    },
    canRetreatAssessor(item) {
      if (!this.settings) {
        return false;
      }
      return this.settings.assessorNow && this.$_isPerson(item.assessor);
    },
    canRetreatFirstCoach(item) {
      if (!this.settings) {
        return false;
      }
      return this.settings.coachNow && this.$_isPerson(item.firstCoach);
    },
    canRetreatSecondCoach(item) {
      if (!this.settings) {
        return false;
      }
      return this.settings.coachNow && this.$_isPerson(item.secondCoach);
    },
    async retreatFirstCoach(id) {
      if (
        await this.$root.confirm({
          message: "Willst du die eingetragenen Betreuung wirklich löschen?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.saving = true;

        await this.apiPatch({
          resource: "thesis/thesis",
          id: id,
          key: "firstCoach",
          value: null,
        });
        this.saving = false;
        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: "schoolYear=" + this.schoolYear.id,
        });
      }
    },
    async retreatSecondCoach(id) {
      if (
        await this.$root.confirm({
          message: "Willst du die eingetragenen Co-Betreuung wirklich löschen?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.saving = true;

        await this.apiPatch({
          resource: "thesis/thesis",
          id: id,
          key: "secondCoach",
          value: null,
        });
        this.saving = false;
        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: "schoolYear=" + this.schoolYear.id,
        });
      }
    },
    async retreatAssessor(id) {
      if (
        await this.$root.confirm({
          message: "Möchtest du dich von dieser Zweitbeurteilung zurückziehen?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.saving = true;

        await this.apiPatch({
          resource: "thesis/thesis",
          id: id,
          key: "assessor",
          value: null,
        });
        this.saving = false;
        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: "schoolYear=" + this.schoolYear.id,
        });
      }
    },
  },
  async created() {
    this.fetchData();
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.name === "ThesisEdit" && to.params.update) {
      await this.fetchData();
    }
    next();
  },
});
</script>
